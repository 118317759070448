import React from 'react';
import styled from 'styled-components';
import { firebaseLog } from '../../utils/firebaselog';

const HomeBookEventPage = () => {
  const ALT_IMG_TEXT = '우리집 이벤트 이미지';
  const width = window.screen.width;
  const onPress = () => {
    firebaseLog('landing_page_click', {
      name: `우리집은 도서관 이벤트`,
    });
    // window.location.href = 'https://www.woodo.kr/notice/view?id=93';
    window.location.href = 'https://woodo.page.link/babygo';
  };
  return (
    <MainWrapper>
      <Wrapper500>
        <img
          src="https://babygo.s3.ap-northeast-2.amazonaws.com/media/app/events/715c3bb3-8f2d-4ff7-ade0-402f9cbcb422.jpg"
          alt={ALT_IMG_TEXT}
        />
      </Wrapper500>
      <FloatingButtonContainer>
        <FloatingButton onClick={onPress}>우리집은 도서관 바로가기</FloatingButton>
      </FloatingButtonContainer>
    </MainWrapper>
    // <PageWrapper>
    //   <EventIMG
    //     src={
    //       'https://babygo.s3.ap-northeast-2.amazonaws.com/media/app/events/f4cec752-ec58-44c1-9892-19b046d6d916.png'
    //     }
    //     alt={ALT_IMG_TEXT}
    //     width={width}
    //   />
    //   <FloatingButton onClick={onPress}>우리집은 도서관 바로가기</FloatingButton>
    // </PageWrapper>
  );
};
export default HomeBookEventPage;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(25, 52, 34);
`;

const Wrapper500 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 525px;
`;

const FloatingButtonContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 2vh;
  width: 95%;
  max-width: 485px;
  align-items: center;
`;

const FloatingButton = styled.div`
  /* position: fixed;
  bottom: 2vh; */
  display: flex;
  min-width: 0.9vw;
  width: 100%;
  padding: 12px;
  border-radius: 30px;

  background-color: #ff3e3c;
  color: white;

  font-size: 21px;
  font-family: 'Gmarket-sans-bold', 'serif';
  text-align: center;
  align-items: center;
  justify-content: center;
`;
const PageWrapper = styled.div`
  :root {
    --vh: 100%;
  }

  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #0e351f;
  overflow: hidden;
`;

const EventIMG = styled.img`
  width: ${props => props.width};
`;

const GoToApp = styled.button`
  position: sticky;
  width: 100%;
  padding: 2vh;
  bottom: 0;
  border: none;
  background: #fbaf16;
  color: #fff;
  font-size: 4.5vw;
  font-weight: 700;
  font-family: 'Gmarket-sans-medium', 'serif';
`;
