import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { firebaseLog } from '../../utils/firebaselog';
import babygoLogo from '../../assets/images/logo1.png';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../api/urls';

export default function DeepLink() {
  const params = useParams();
  const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);
  const isAndroid = /Android/i.test(window.navigator.userAgent);
  const [deeplinkUrl, setDeeplinkUrl] = useState('');

  const updateMetaTag = () => {
    const appleItunesAppTag = document.createElement('meta');
    appleItunesAppTag.name = 'apple-itunes-app';
    appleItunesAppTag.content = 'app-id=785952884';

    const head = document.querySelector('head');
    head.appendChild(appleItunesAppTag);
  };

  const hrefHandler = () => {
    if (isIOS) {
      window.location.href = decodeURI(
        'https://apps.apple.com/kr/app/%EB%A1%AF%EB%8D%B0%EB%B0%B1%ED%99%94%EC%A0%90/id785952884?l=en-GB',
      );
    } else if (isAndroid) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.innov.lottecoupon&hl=ko&gl=US';
    }
  };

  const isPageVisible = () => {
    let attrNames = ['hidden', 'webkitHidden'];
    for (let i = 0, len = attrNames.length; i < len; i++) {
      if (typeof document[attrNames[i]] !== 'undefined') {
        return !document[attrNames[i]];
      }
    }
    return true;
  };
  const moveToLink = path => {
    let os = 'notOs';
    if (isAndroid) {
      os = 'andoroid';
    } else if (isIOS) {
      os = 'ios';
    }
    firebaseLog('deeplink_app_click', {
      name: `deeplink_app_click_${os}`,
      dl: params.name,
    });

    const clickedAt = new Date().getTime();
    const tid = setTimeout(() => {
      let now = new Date().getTime();
      if (isPageVisible() && now - clickedAt < 2000 + 100) {
        hrefHandler();
      }
    }, 2000);
    // eslint-disable-next-line no-restricted-globals
    document.addEventListener('visibilitychange', function clear() {
      clearTimeout(tid);
    });
    // eslint-disable-next-line no-restricted-globals
    top.window.location.href = deeplinkUrl || path;
  };

  useEffect(() => {
    const path =
      'lottecoupon://gate?page=a0240&url=https%3A%2F%2Fm.lotteshopping.com%2Fcpn%2FcpnDetail%3FcpnInfoNo%3DCPM00000000000092756%26utm_source%3Downed%26utm_medium%3Dlms%26utm_campaign%3DLMS%EC%BA%A0%ED%8E%98%EC%9D%B8;package=com.innov.lottecoupon;end';
    moveToLink(path);
  }, []);

  return (
    <Wrapper isIOS={isIOS}>
      <LogoWrapper />
      <Content>
        <ContentTextWrapper>
          <ContentText> 앱을 실행하시려면!</ContentText>
          <ContentText>
            <ContentTextStressed>실행</ContentTextStressed>하기 버튼을 눌려주세요
          </ContentText>
        </ContentTextWrapper>
        <ContentButton onClick={moveToLink}>앱 실행하기</ContentButton>
      </Content>
      <BottomAppInstallButton
        onClick={() => {
          let os = 'notOs';
          if (isAndroid) {
            os = 'andoroid';
          } else if (isIOS) {
            os = 'ios';
          }

          firebaseLog('deeplink_app_install_click', {
            name: `deeplink_app_install_click_${os}`,
            dl: params.name,
          });
          hrefHandler();
        }}
      >
        앱을 설치하고 싶으시다면
      </BottomAppInstallButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${({ isIOS }) => (isIOS ? '-webkit-fill-available' : '92vh')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`;
const LogoWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;

  @media (max-width: 700px) {
    width: 100%;
  }
`;
const Logo = styled.img`
  width: 140px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContentTextWrapper = styled.div`
  margin-bottom: 20px;
`;
const ContentText = styled.div`
  margin-bottom: 4px;
  font-size: 1.1em;
  letter-spacing: -1px;
`;
const ContentTextStressed = styled.span`
  color: #fbaf14;
  font-weight: 700;
`;
const ContentButton = styled.button`
  border: none;
  border-radius: 36px;
  padding: 12px 36px;
  background-color: #fbaf14;
  color: #fff;
  font-weight: 700;
  font-size: 1.1em;
  letter-spacing: -1px;
`;
const BottomAppInstallButton = styled.button`
  border: none;
  background-color: #efefef;
  font-size: 1em;
  color: #888;
  border-radius: 20px;
  padding: 10px 36px;
  letter-spacing: -1px;
  margin-bottom: 20px;
`;
