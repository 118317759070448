import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  roomTypeArr: [],
};

const roomTypeSlice = createSlice({
  name: 'roomtype',
  initialState,
  reducers: {
    addRoomType(state, action) {
      state.roomTypeArr.push(action.payload);
    },
    updateRoomType(state, action) {
      state.roomTypeArr = action.payload;
    },
    resetRoomType(state, action) {
      state.roomTypeArr = [];
    },
  },
});

export default roomTypeSlice;
