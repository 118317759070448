import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PATH_BASE } from './paths';
import 'antd/dist/antd.css'; // antd CSS 파일 로드
import '../src/styles/header.css';
import AppDownload from './pages/AppDownload/AppDownload';
import DeepLink from './pages/AppDownload/DeepLink';
import Test from './pages/AppDownload/Test';
import AnonymousQuestionPage from './pages/general/AnonymousQuestion';
import HomeBookEventPage from './pages/event/bookEvent';

//page component
const MainRouter = lazy(() => import('./MainRouter'));
const PlaceDetail = lazy(() => import('./pages/place/PlaceDetail'));
const NewPlaceDetail = lazy(() => import('./pages/place/NewPlaceDetail'));
const KBCardEvent = lazy(() => import('./pages/KBCardEvent/KBCardEvent'));
const YearEndSettlement = lazy(() => import('./pages/year-end-settlement/YearEndSettlement'));
const ShinsegaeCouponPage = lazy(() => import('./pages/popUpEventPages/ShinsegaeCouponPage'));
const ScienceCouponPage = lazy(() => import('./pages/popUpEventPages/ScienceCouponPage'));
const DdayPage = lazy(() => import('./pages/d-dayPage/DdayPage'));
const AprilFoolsDayEventPage = lazy(() => import('./pages/popUpEventPages/AprilFoolsDayEventPage'));
const AprilFoolsDayEventForKaKaoPage = lazy(() =>
  import('./pages/popUpEventPages/AprilFoolsDayEventForKakao'),
);
const PlayInTheBoxEventPage = lazy(() => import('./pages/popUpEventPages/PlayInTheBoxEventPage'));
const AlleywayEventPage = lazy(() => import('./pages/popUpEventPages/AlleywayEventPage.js'));
const HoduEventPage = lazy(() => import('./pages/popUpEventPages/HoduEventPage.js'));
const FlyEventPage = lazy(() => import('./pages/event/FlyEventPage'));
const CrtwSellPage = lazy(() => import('./pages/crtwSellPage/CrtwSellPage'));
const PinkfongRoulettePage = lazy(() => import('./pages/pinkfongRoulette/PinkfongRoulette'));
const RouletteEventPage = lazy(() => import('./pages/rouletteEvent/RouletteEvent'));
const FsFcEventPage = lazy(() => import('./pages/fcFsEvent/FcFsEvent'));
const XTheSkyPage = lazy(() => import('./pages/event/XTheSkyPage'));
const PinkPongLuckyBoxPage = lazy(() => import('./pages/event/PinkPongLuckyBox'));
const AthleticEventPage = lazy(() => import('./pages/athleticEvent/AthleticEventPage'));
const ApplyJejuBasketBall = lazy(() => import('./pages/jeju/jejuBasketBall/ApplyJejuBasketBall'));
const FamilyStar = lazy(() => import('./pages/FamilyStarEvent/FamilyStarEvent'));
const Jeju2024Workout = lazy(() => import('./pages/jeju/Jeju2024Workout/Jeju2024Workout'));
const AprilFoolsDay2024 = lazy(() => import('./pages/AprilFoolsDay2024/AprilFoolsDay2024'));
const AprilFoolsDay2024Apply = lazy(() =>
  import('./pages/AprilFoolsDay2024Apply/AprilFoolsDay2024Apply'),
);
const PoliceEvent = lazy(() => import('./pages/event/PoliceEvent'));
const ApplyJejuBasketBallApr2024 = lazy(() =>
  import('./pages/jeju/2024AprilBasketball/ApplyJejuBasketBall'),
);
const HeroPlayPark = lazy(() => import('./pages/event/HeroPlayPark'));

function AppInner() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>...loading</div>}>
        <Switch>
          <Route path={PATH_BASE} component={MainRouter} />
          {/*<Route path="/place-detail" component={PlaceDetail} />*/}
          <Route path="/placedetail" component={NewPlaceDetail} />
          <Route path="/place-detail" component={NewPlaceDetail} /> {/* 임시로 추가 */}
          {/*국민카드 이벤트 페이지*/}
          <Route path="/kbcard-event" component={KBCardEvent} />
          <Route path="/shinsegae-event" component={ShinsegaeCouponPage} />
          <Route path="/science-coupon-event" component={ScienceCouponPage} />
          <Route path="/april-fools-day-event" component={DdayPage} />
          <Route path="/april-fools-day-event-dday" component={AprilFoolsDayEventPage} />
          <Route
            path="/april-fools-day-event-for-kakao"
            component={AprilFoolsDayEventForKaKaoPage}
          />
          <Route path="/play-in-the-box-event" component={PlayInTheBoxEventPage} />
          <Route path="/alleyway-event" component={AlleywayEventPage} />
          <Route path="/hoduenglish-event" component={HoduEventPage} />
          <Route path="/family-star-event" component={FamilyStar} />
          {/* 만우절 이벤트 추첨 페이지 */}
          <Route path="/fly-event-page" component={FlyEventPage} />
          {/* 애가 나들이상품 판매 페이지 */}
          <Route path="/crtw-sell-page" component={CrtwSellPage} />
          <Route path="/crtw" component={CrtwSellPage} />
          {/* 핑크퐁 룰렛 이벤트 페이지 */}
          <Route path="/pinkfong-event" component={PinkfongRoulettePage} />
          <Route path="/roulette-event/:eventName" component={RouletteEventPage} />
          <Route path="/fcfs-event/:eventName" component={FsFcEventPage} />
          {/* 이벤트용 페이지 목록*/}
          <Route path="/police-event" component={PoliceEvent} />
          <Route path="/homebook" component={HomeBookEventPage} />
          <Route path="/year-end" component={YearEndSettlement} />
          <Route path="/pinkfong-luckybox" component={PinkPongLuckyBoxPage} />
          <Route path="/athletic-event" component={AthleticEventPage} />
          <Route path="/apply-jeju-basketball" component={ApplyJejuBasketBall} />
          <Route path="/apply-jeju-basketball-apr-2024" component={ApplyJejuBasketBallApr2024} />
          <Route path="/apply-2024-march-kids-workout" component={Jeju2024Workout} />
          <Route path="/april-fools-day-event-2024" component={AprilFoolsDay2024} />
          <Route path="/april-fools-day-event-2024-apply" component={AprilFoolsDay2024Apply} />
          <Route path="/hero-play-park-event" component={HeroPlayPark} />
          {/* 앱 설치 페이지 */}
          <Route path="/app-download" component={AppDownload} />
          <Route path="/dl/:name" component={DeepLink} />
          <Route path="/tmp/app" component={Test} />
          {/* 직원용 */}
          <Route path="/anonymous-question" component={AnonymousQuestionPage} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppInner;
