let url = 'http://localhost:8000/api';

url = 'http://13.209.73.157'; // 개발서버 지우지 말아주세요

url = 'http://localhost:8000/api';
// url = 'http://dev.babygo.kr/api';

// url = 'http://172.30.1.71:8000'

if (process.env.NODE_ENV === 'production') {
  url = 'https://api.babygo.kr/api';
}

export const BASE_URL = url;
