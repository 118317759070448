import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyCcfiS4vlDlbp-r1e5DtkkZhw-MGJnYzSI',
  authDomain: 'babygo-2124a.firebaseapp.com',
  databaseURL: 'https://babygo-2124a.firebaseio.com',
  projectId: 'babygo-2124a',
  storageBucket: 'babygo-2124a.appspot.com',
  messagingSenderId: '375904047693',
  appId: '1:375904047693:web:b877cd2d546eda902f0204',
  measurementId: 'G-VVCK7K39KG',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const firebaseLog = (eventName = '', eventParams = {}) => {
  logEvent(analytics, eventName, eventParams);
};
