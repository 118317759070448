export const PATH_BASE = '/webadmin';
export const PATH_PLACE = PATH_BASE + '/place';
export const PATH_STAMP = PATH_BASE + '/event';
export const PATH_REVIEW = PATH_BASE + '/review';
export const PATH_ANALYTICS = PATH_BASE + '/analytics';

export const PATH_PLACE_THUMBNAIL_CHOICE = PATH_PLACE + '/thumbnail-choice';
export const PATH_PLACE_IMAGE_CHOICE = PATH_PLACE + '/detailimage-update';

export const PATH_STAMP_EVENT = PATH_STAMP + '/stamp-event';
export const PATH_LUCKYBAG_EVENT = PATH_STAMP + '/lucky-bag';

export const PATH_REVIEW_LIST = PATH_REVIEW + '/review-list';

export const PATH_ANALYTICS_USER = PATH_ANALYTICS + '/user';

export const PATH_PLACE_DETAIL = PATH_BASE + '/place-detail';

export const PATH_PLACE_REGISTER = PATH_PLACE + '/place-register';
