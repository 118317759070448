import axios from 'axios';

const AnonymousQuestionPage = () => {
  const sendQuestion = async e => {
    e.preventDefault();
    const url = 'https://hooks.slack.com/services/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';
    const target = e.currentTarget.name.value;
    const content = e.currentTarget.content.value;
    const text = `질문대상: ${target}\n
[질문]\n
${content}`;
    const data = {
      channel: 'C05TH0RSUSC',
      username: '질문봇',
      text: text,
      icon_emoji: ':안녕뚜야:',
    };
    const res = await axios.post(url, JSON.stringify(data), {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          delete headers.post['Content-Type'];
          return data;
        },
      ],
    });
    if (res.status === 200) {
      const after = {
        channel: 'C05TH0RSUSC',
        username: '구분선봇',
        text: '-------------------------------------',
        icon_emoji: ':white_check_mark:',
      };
      await axios.post(url, JSON.stringify(after), {
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.post['Content-Type'];
            return data;
          },
        ],
      });
      alert('전송이 완료되었습니다.');
      window.location.reload();
    }
  };
  return (
    <div style={{ padding: 10 }}>
      <h1>익명 질문</h1>
      <div>
        익명으로 남길 수 있는 질문을 남길 수 있습니다.
        <br />
        제출시 슬랙 비공개 채널로 전송되며 관계자외 볼 수 없습니다.
      </div>
      <hr />
      <form onSubmit={sendQuestion}>
        <dl>
          <dt>질문대상</dt>
          <dd>
            <input type="text" name="name" />
          </dd>
          <dt>내용</dt>
          <dd>
            <textarea name="content" style={{ width: 400, height: 400 }} />
          </dd>
          <input type="submit" value="제출" />
        </dl>
      </form>
    </div>
  );
};

export default AnonymousQuestionPage;
