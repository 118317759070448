import React, { useEffect } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import store from './store';
import AppInner from './AppInner';

window.isProduction = process.env.REACT_APP_IS_PRODUCTION === 'y';

// window.headers = {
//   "Access-Control-Allow-Origin": "*",
//   "Access-Control-Allow-Credentials": "false",
//   "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
//   "Access-Control-Allow-Headers":
//     "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
// };

const tagManagerArgs = {
  gtmId: 'GTM-TV9H4FN',
  dataLayer: {
    userId: 'babygo',
    userProject: 'babygoweb-place-detail',
  },
};

function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Provider store={store}>
      <AppInner />
    </Provider>
  );
}

export default App;
