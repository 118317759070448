import React, { useEffect } from 'react';
import styled from 'styled-components';

import babygoLogo from '../../assets/images/logo1.png';
import { firebaseLog } from '../../utils/firebaselog';

export default function AppDownload() {
  const pathParam = window.location.href.split('app-download/')[1];
  const isIOS = /iPhone|iPad|iPod/i.test(window.navigator.userAgent);
  const isAndroid = /Android/i.test(window.navigator.userAgent);

  const updateMetaTag = () => {
    const appleItunesAppTag = document.createElement('meta');
    appleItunesAppTag.name = 'apple-itunes-app';
    appleItunesAppTag.content = 'app-id=1479205228';

    const head = document.querySelector('head');
    head.appendChild(appleItunesAppTag);
  };

  const hrefHandler = () => {
    if (isIOS) {
      window.location.href = decodeURI(
        'https://apps.apple.com/kr/app/%EC%95%A0%EA%B8%B0%EC%95%BC%EA%B0%80%EC%9E%90-%EB%86%80%EC%9D%B4-%ED%82%A4%EC%A6%88%EC%B2%B4%ED%97%98-%EC%97%AC%ED%96%89-%EB%A6%AC%EB%B7%B0-%EB%AA%A8%EB%91%90-%ED%95%9C%EB%B2%88%EC%97%90/id1479205228',
      );
    } else if (isAndroid) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.basern&hl=ko&gl=US';
    }
  };

  const confirmOnClick = () => {
    window.location.href = `babygo://${pathParam}`;
  };

  const isPageVisible = () => {
    let attrNames = ['hidden', 'webkitHidden'];
    for (let i = 0, len = attrNames.length; i < len; i++) {
      if (typeof document[attrNames[i]] !== 'undefined') {
        return !document[attrNames[i]];
      }
    }
    return true;
  };
  const moveToLink = () => {
    let os = 'notOs';
    if (isAndroid) {
      os = 'andoroid';
    } else if (isIOS) {
      os = 'ios';
    }
    const deeplinkUrl = `babygo://${pathParam}`;
    firebaseLog('deeplink_app_click', {
      name: `deeplink_app_click_${os}`,
      dl: deeplinkUrl,
    });

    const clickedAt = new Date().getTime();
    const tid = setTimeout(() => {
      let now = new Date().getTime();
      if (isPageVisible() && now - clickedAt < 2000 + 100) {
        hrefHandler();
      }
    }, 2000);
    // eslint-disable-next-line no-restricted-globals
    document.addEventListener('visibilitychange', function clear() {
      clearTimeout(tid);
    });
    // eslint-disable-next-line no-restricted-globals
    top.window.location.href = deeplinkUrl;
  };
  useEffect(() => {
    moveToLink();
  }, []);

  return (
    <Wrapper isIOS={isIOS}>
      <LogoWrapper>
        <Logo src={babygoLogo} />
      </LogoWrapper>
      <Content>
        <ContentTextWrapper>
          <ContentText>애기야가자 앱에서 정보를 더 확인하고 싶으시다면!</ContentText>
          <ContentText>
            <ContentTextStressed>확인</ContentTextStressed>하기 버튼을 눌려주세요
          </ContentText>
        </ContentTextWrapper>
        <ContentButton onClick={confirmOnClick}>확인하기</ContentButton>
      </Content>
      <BottomAppInstallButton onClick={hrefHandler}>
        앱을 설치하고 싶으시다면
      </BottomAppInstallButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  min-height: ${({ isIOS }) => (isIOS ? '-webkit-fill-available' : '92vh')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`;
const LogoWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;

  @media (max-width: 700px) {
    width: 100%;
  }
`;
const Logo = styled.img`
  width: 140px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContentTextWrapper = styled.div`
  margin-bottom: 20px;
`;
const ContentText = styled.div`
  margin-bottom: 4px;
  font-size: 1.1em;
  letter-spacing: -1px;
`;
const ContentTextStressed = styled.span`
  color: #fbaf14;
  font-weight: 700;
`;
const ContentButton = styled.button`
  border: none;
  border-radius: 20px;
  padding: 12px 36px;
  background-color: #fbaf14;
  color: #fff;
  font-weight: 700;
  font-size: 1.1em;
  letter-spacing: -1px;
`;
const BottomAppInstallButton = styled.button`
  border: none;
  background-color: #efefef;
  font-size: 1em;
  color: #888;
  border-radius: 20px;
  padding: 10px 36px;
  letter-spacing: -1px;
  margin-bottom: 20px;
`;
